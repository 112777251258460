<!--管控数据-->
<template>
    <div class="controlInstanceList">
        <table-list
            ref="tableList"
            :extr="params"
            :code="params.code"
        ></table-list>
    </div>
</template>

<script>
import tableList from '@/views/tableList/index';
export default {
    components: { tableList },
    name: 'control-instance-list',
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            params: {
                code: {
                    TableCode: 'control_instance_list',
                    QueryString: '?control_type=2&business_type=2&business_number=',
                },
            },
        };
    },
    created() {
        this.params.code.QueryString += this.extr.task_number;
    },
};
</script>

<style lang="stylus" scoped>
.controlInstanceList
    width 100%;
    height 100%;
</style>
